import { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import type { z } from "zod";

import { EmailIcon, LockIcon } from "assets";
import { LoginContainer } from "routes/login/components/LoginContainer";
import { LoginInfoContainer } from "routes/login/components/LoginInfoContainer";
import SignupInput from "routes/login/components/LoginInput";
import { Form, LoginWrapper, SubmitButton } from "routes/login/components/styledComponents";
import { authenticateWithEmail, signupWithEmail } from "routes/login/queries";
import { getErrorMessage, signupSchema } from "routes/login/utils/validation/emailPasswordSchemas";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import Body1 from "shared/atoms/Typography/Body1";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { LOGIN_URL, isNewAuthEnabled } from "utils/auth";
import { getAppRawDataRegion } from "utils/region";

type FormValues = z.infer<typeof signupSchema>;

const EmailSignupForm = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const match = useMatch("*");
  const [authRequest, setAuthRequest] = useState({ loading: false, error: false, message: "" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onBlur",
    resolver: zodResolver(signupSchema),
    defaultValues: {
      email: new URLSearchParams(search).get("e") || "",
    },
  });
  const confirmationToken = new URLSearchParams(search).get("t");

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.SIGNUP.EMAIL, match.pathname);
    }
  }, [match?.pathname]);

  const onSubmit: SubmitHandler<FormValues> = async body => {
    setAuthRequest({ loading: true, error: false, message: "" });
    try {
      await signupWithEmail({
        ...body,
        confirmation_token: confirmationToken as string,
        country_code: getAppRawDataRegion(),
      });

      if (isNewAuthEnabled()) {
        await authenticateWithEmail({ email: body.email, password: body.password });
      }

      navigate("/onboarding");
    } catch (error) {
      if (error != null && axios.isAxiosError(error)) {
        setAuthRequest({
          loading: false,
          error: true,
          message: error?.response?.status === 401 ? t("errors.invalid_login") : t("errors.generic"),
        });
      }
    }
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginInfoContainer title={t("signup.info.email.title")}>{t("signup.info.email.body")}</LoginInfoContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SignupInput
            type="email"
            icon={EmailIcon}
            label={t("form.email")}
            placeholder={t("form.email")}
            {...register("email")}
            error={getErrorMessage(t, errors.email)}
            disabled
            dataTestId="signup_email_form_email"
          />
          <SignupInput
            icon={LockIcon}
            label={t("form.password")}
            type="password"
            placeholder={t("form.password")}
            {...register("password")}
            minLength={8}
            error={getErrorMessage(t, errors.password)}
            dataTestId="signup_email_form_password"
          />
          <SignupInput
            icon={LockIcon}
            label={t("form.confirm_password")}
            type="password"
            placeholder={t("form.confirm_password")}
            {...register("password_confirmation")}
            error={getErrorMessage(t, errors.password_confirmation)}
            dataTestId="signup_email_form_confirm_password"
          />

          {authRequest.error && (
            <Notification m="30px 0 0 0" type="danger">
              {authRequest?.message}
            </Notification>
          )}
          <StyledSubmitButton disabled={authRequest.loading} type="submit" data-testid="signup_email_form_submit_btn">
            {authRequest.loading ? <Spinner small /> : t("signup.info.email.title")}
          </StyledSubmitButton>
        </Form>
        <AlreadyHaveAccount>
          <Body1 style={{ color: theme.colors.primary.base, marginTop: "12px", marginRight: "8px" }}>
            {t("form.already_have_account")}
          </Body1>
          <Link to={LOGIN_URL}>{t("form.log_in")}</Link>
        </AlreadyHaveAccount>
      </LoginContainer>
    </LoginWrapper>
  );
};

export default EmailSignupForm;

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 16px;
`;

const AlreadyHaveAccount = styled.div`
  text-align: center;
  margin-bottom: 12px;
`;
